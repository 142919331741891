<!-- When upgrading to Vue3: swap "value" and "modelValue" -->
<template>
    <v-row>
        <v-col cols="12" sm="6">
            <v-text-field v-bind="$attrs" :required="required" persistent-placeholder label="Straat" type="text" v-model="modelValue.street" :rules="required ? getRequiredRules('Straat moet ingevuld worden') : []"/>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field :required="required" persistent-placeholder label="Huisnummer" type="text"  v-model="modelValue.number" :rules="required ? getRequiredRules('Huisnummer moet ingevuld worden') : []"/>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field v-bind="$attrs" persistent-placeholder label="Bus" type="text"  v-model="modelValue.bus"/>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field v-bind="$attrs" :required="required" persistent-placeholder label="Postcode" type="text" v-model="modelValue.postalCode" :rules="required ? getRequiredRules('Postcode moet ingevuld worden') : []"/>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field v-bind="$attrs" :required="required" persistent-placeholder label="Stad" type="text" v-model="modelValue.city" :rules="required ? getRequiredRules('Stad moet ingevuld worden') : []"/>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field v-bind="$attrs" persistent-placeholder label="Provincie" type="text" v-model="modelValue.region" />
        </v-col>
        <v-col cols="12" sm="6">
            <CountryCodeSelect v-bind="$attrs" :required="required" persistent-placeholder label="Land" v-model="modelValue.countryCode" :rules="required ? getRequiredRules('Land moet ingevuld worden') : []"/>
        </v-col>
    </v-row>
</template>
<script>
import CountryCodeSelect from "@/components/shared/fields/CountryCodeSelect.vue";
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';

export default {
    name: "AddressInput",
    components: {
        CountryCodeSelect
    },
    props: {
        value: Object,
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:value'],
    computed: {
        modelValue: {
            get() {
                return this['value'] || {}
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    methods: {
        getRequiredRules
    }
}
</script>