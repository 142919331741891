<template>
    <div>
      <ClientDetailItemEditCard confirmation-message="Klant informatie werd succesvol gewijzigd"
        title="Bewerk klant informatie" :clientId="clientId" 
        update-store-action="clientsModule/updateClientInformation" :update-store-action-payload="{clientId, clientInformation}"
        detail-view-route-location=""
        >
        <template v-slot:form-fields>
          <v-row>
            <v-col cols="12" md="6">
              <DatePicker v-model="clientInformation.startDate" label="Start datum" required :default-now="false" />
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="clientInformation.endDate" label="Eind datum" :min="clientInformation.startDate ? new Date(clientInformation.startDate) : null" :default-now="false"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="clientInformation.vat" persistent-placeholder label="btw nummer" type="text" />
            </v-col>
            <v-col cols="12">
              <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-map-marker</v-icon> Adres</v-subheader>
              <AddressInput v-model="clientInformation.address"/>
            </v-col>
          </v-row>
        </template>
      </ClientDetailItemEditCard>
    </div>
  </template>
  
  <script>
  import ClientDetailItemEditCard from "@/views/client/forms/ClientDetailItemEditCard";
  import DatePicker from "@/components/shared/DatePicker.vue";
  import AddressInput from "../../../components/shared/fields/AddressInput.vue";
  
  export default {
    name: "ClientDetailEditClientInformation",
    props: {
      clientId: {
        type: String,
        required: true
      }
    },
    components: {
      ClientDetailItemEditCard,
      DatePicker,
      AddressInput
    },
    data() {
      return {
        clientInformation: {
          startDate: null,
          endDate: null,
          vat: null,
          address: {},
        }, 
        promises: [
            this.$store.dispatch('clientsModule/fetchClientInformation', {clientId: this.clientId})
              .then((clientInformation) => {
                this.clientInformation = clientInformation
                if (!this.clientInformation.address) {
                    this.clientInformation.address = {}
                }
            })
          ]
      }
    },
  }
  </script>
  